import { AppBar, Toolbar, Button, IconButton, Tooltip, Box, Link } from '@mui/material';
import Brightness2Icon from '@mui/icons-material/Brightness2';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import { useAuthContext } from '../contexts/AuthProvider';
import { useTheme } from '../contexts/ThemeContext';
import logo from '../assets/logo_storeroom.svg';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  const { logout } = useAuthContext();
  const { darkMode, toggleDarkMode } = useTheme();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <AppBar position="static" color="default" elevation={1}>
      <Toolbar sx={{ justifyContent: 'space-between' }}>

        <img src={logo} alt="Logo" style={{ height: 32 }} 
          onClick={() => navigate('/dashboard')}
          onMouseOver={e => e.currentTarget.style.cursor = 'pointer'}
          />

        <Link href="/scrape" component={Link} color="inherit" underline="none">
          Scrape
        </Link>

        <Link href="/products" component={Link} color="inherit" underline="none">
          Products
        </Link>

        <Link href="/storebrand-skus" component={Link} color="inherit" underline="none">
          Storebrand SKUs
        </Link>

        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>

          <Tooltip title="Toggle Dark Mode" arrow>
            <IconButton color="inherit" onClick={toggleDarkMode}>
              {darkMode ? <Brightness4Icon /> : <Brightness2Icon />}
            </IconButton>
          </Tooltip>

          <Button color="inherit" onClick={handleLogout}>
            Logout
          </Button>

        </Box>

      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
