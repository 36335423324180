import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useAuthContext } from '../contexts/AuthProvider';

interface StorebrandSku {
    storebrand: string;
    sku: string;
    first_scraped_at: number;
    last_updated_details_at: number;
    title: string;
    brand: string;
    size: string;
    is_sold_by_weight: boolean;
    description: string;
    category_detail: string;
    barcode: string;
}

const StorebrandSkus = () => {
  const navigate = useNavigate();
  const [storebrandSkus, setStorebrandSkus] = useState<StorebrandSku[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { accessToken } = useAuthContext();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch('/api/storebrand-sku/search', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
          body: JSON.stringify({}), // Empty search returns all products
        });
        
        if (!response.ok) throw new Error('Failed to fetch products');
        
        const data = await response.json();
        setStorebrandSkus(data);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'An error occurred');
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [accessToken]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div style={{ padding: '1rem' }}>
      <h1 style={{ fontSize: '1.5rem', marginBottom: '1rem' }}>Storebrand SKUs</h1>
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))', gap: '1rem' }}>
        {storebrandSkus.map((storebrandSku) => (
          <div
            key={storebrandSku.sku}
            onClick={() => navigate(`/storebrand-sku/${storebrandSku.storebrand}/${storebrandSku.sku}`)}
            style={{
              padding: '1rem',
              border: '1px solid #ddd',
              borderRadius: '4px',
              cursor: 'pointer',
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
            }}
          >
            <h2 style={{ fontSize: '1.2rem', marginBottom: '0.5rem' }}>{storebrandSku.title}</h2>
            <p style={{ color: '#666' }}>Click to view details</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default StorebrandSkus;
