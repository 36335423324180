import { RecentScrapeRunSummary } from '../components/scrape/RecentScrapeRunSummary';
import { RawScrapeFileSummary } from '../components/scrape/RawScrapeFileSummary';

const Scrape = () => {
  return (
    <div className="scrape">
      <RecentScrapeRunSummary />
      <RawScrapeFileSummary />
    </div>
  );
};

export default Scrape;
