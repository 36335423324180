import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Container, Box, CssBaseline, ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material';
import { AuthProvider } from './contexts/AuthProvider';
import { useTheme } from './contexts/ThemeContext';
import ProtectedRoute from './components/ProtectedRoute';
import Navbar from './components/Navbar';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Scrape from './pages/Scrape';
import Products from './pages/Products';
import Product from './pages/Product';
import Store from './pages/Store';
import StorebrandSkus from './pages/StorebrandSkus';
import StorebrandSku from './pages/StorebrandSku';

function App() {
  const { darkMode } = useTheme();

  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
      primary: {
        main: darkMode ? '#90caf9' : '#282c34',
      },
      secondary: {
        main: '#61dafb',
      },
      background: {
        default: darkMode ? '#303030' : '#fff',
        paper: darkMode ? '#424242' : '#fff',
      },
    },
  });

  return (
    <MuiThemeProvider theme={theme}>
      <BrowserRouter>
        <AuthProvider>
          <CssBaseline />
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            minHeight: '100vh',
            margin: 0,
            padding: 0
          }}>
            <Navbar />
            <Container component="main" sx={{ 
              mt: 4, 
              mb: 4, 
              px: { xs: 2, sm: 3 }
            }}>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route
                  path="/*"
                  element={
                    <ProtectedRoute>
                      <Routes>
                        <Route path="/" element={<Navigate to="/dashboard" replace />} />
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/scrape" element={<Scrape />} />
                        <Route path="/products" element={<Products />} />
                        <Route path="/product/:id" element={<Product />} />
                        <Route path="/store/:storeName" element={<Store />} />
                        <Route path="/storebrand-skus" element={<StorebrandSkus />} />
                        <Route path="/storebrand-sku/:storebrand/:sku" element={<StorebrandSku />} />
                      </Routes>
                    </ProtectedRoute>
                  }
                />
              </Routes>
            </Container>
          </Box>
        </AuthProvider>
      </BrowserRouter>
    </MuiThemeProvider>
  );
}

export default App;
