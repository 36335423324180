import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useAuthContext } from '../contexts/AuthProvider';

interface StoreScrapeSummary {
  storeBrand: string;
  storeName: string;
  storeNameDebranded: string;
  scrapeRuns: {
    scrapeStartDateNZT: string;
    scrapeStartAt: number;
    scrapeEndAt: number;
    numSkus: number;
    wasSuccess: boolean;
  }[];
  latestScrapeRun: {
    startAt: number;
    ageHours: number;
    dateNZT: string;
    skuCoveragePercent: number;
    numSkus: number;
  };
  recentMaxNumSkus: number;
}

const StorebrandSkus = () => {
  const { storeName } = useParams();
  const [storeScrapeSummary, setStoreScrapeSummary] = useState<StoreScrapeSummary>({} as StoreScrapeSummary);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { accessToken } = useAuthContext();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch('/api/scrape/get-recent-scrape-runs-by-store', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
          body: JSON.stringify({"storeName": storeName}),
        });
        
        if (!response.ok) throw new Error('Failed to fetch scrape runs for store');
        
        const data = await response.json();
        setStoreScrapeSummary(data);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'An error occurred');
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [accessToken, storeName]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const sortedScrapeRuns = [...storeScrapeSummary.scrapeRuns].sort((a, b) => b.scrapeStartAt - a.scrapeStartAt);

  return (
    <div style={{ padding: '1rem' }}>
      <h1 style={{ fontSize: '1.5rem', marginBottom: '1rem' }}>Scrape runs for {storeName}</h1>
      <h3>Last scraped {storeScrapeSummary.latestScrapeRun.ageHours} hours ago</h3>

      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))', gap: '1rem' }}>
        {sortedScrapeRuns.map((scrapeRun) => (
          <div
            key={scrapeRun.scrapeStartAt}
            style={{
              padding: '1rem',
              border: '1px solid #ddd',
              borderRadius: '4px',
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
            }}
          >
            <h2 style={{ fontSize: '1.2rem', marginBottom: '0.5rem' }}>{scrapeRun.scrapeStartDateNZT}</h2>
            <p style={{ color: '#666' }}>{scrapeRun.scrapeStartAt}</p>
            <p style={{ color: '#666' }}>{scrapeRun.numSkus}</p>
            <p style={{ color: '#666' }}>{scrapeRun.wasSuccess ? 'Success' : 'Failure'}</p>
          </div>
        ))}
      </div>

    </div>
  );
}

export default StorebrandSkus;
