import { useEffect, useState } from 'react';
import { useAuthContext } from '../../contexts/AuthProvider';
import { Typography, Box, CircularProgress, Alert, Paper } from '@mui/material';
import ScrapeStoreCard from "./ScrapeStoreCard";
import ScrapeStorebrandSummary from "./ScrapeStorebrandSummary";


interface ScrapeRun {
  scrapeStartDateNZT: string;
  scrapeStartAt: number;
  scrapeEndAt: number;
  numSkus: number;
}

interface StoreData {
  storeBrand: string;
  storeName: string;
  storeNameDebranded: string;
  scrapeRuns: ScrapeRun[];
  latestScrapeRun: {
    ageHours: number;
    dateNZT: string;
    skuCoveragePercent: number;
    numSkus: number;
  };
  recentMaxNumSkus: number;
}

export function RecentScrapeRunSummary() {
  const [scrapeRunData, setScrapeRunData] = useState<Record<string, StoreData[]> | null>(null);
  const [currentTimeNZTFormatted, setCurrentTimeNZTFormatted] = useState<string>('');
  const [currentDateNZT, setCurrentDateNZT] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const { accessToken } = useAuthContext();


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          '/api/scrape/get-recent-scrape-run-stats',
          {headers: {'Authorization': `Bearer ${accessToken}`}}
        );
        const responseData = await response.json();
        setScrapeRunData(responseData);
        
        const loadedDate = response.headers.get('date') || '0'
        const loadedTimeNZT = new Date(loadedDate).toLocaleString("en-US", { timeZone: "Pacific/Auckland"})
        const loadedTimeNZTFormatted = new Date(loadedTimeNZT).toLocaleString("sv-SE") // "YYYY-MM-DD HH:MM:SS"
        const loadedDateNZT = loadedTimeNZTFormatted.split(" ")[0]
        setCurrentTimeNZTFormatted(loadedTimeNZTFormatted)
        setCurrentDateNZT(loadedDateNZT)
      } catch (err) {
        setError('Failed to load recent scrape runs');
      }

    };

    fetchData();
  }, [accessToken]);

  // Sort the stores in each storebrand by storeNameDebranded, alphabetically:
  if (scrapeRunData) {
    for (const storeBrand in scrapeRunData) {
      scrapeRunData[storeBrand].sort((a, b) => {
        const nameA = a.storeNameDebranded.toUpperCase(); // ignore upper and lowercase
        const nameB = b.storeNameDebranded.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      
        // Return 0 otherwise (names must be equal):
        return 0;
      });
    }
  }

  if (error) {
    return <Alert severity="error">Error: {error}</Alert>;
  }

  return (
    <Paper elevation={2} sx={{ p: 3, mb: 3, maxWidth: 1010, mx: 'auto' }}>
      <Typography variant="h4" gutterBottom>
        Recent Scrape Runs
      </Typography>

      <p>As of {currentTimeNZTFormatted ? `${currentTimeNZTFormatted} NZT` : '...'}</p>

      {scrapeRunData == null && (
        <CircularProgress size={64} />
      )}

      {scrapeRunData && (
        <>
        {Object.entries(scrapeRunData).sort().map(([storeBrand, stores]) => (
          <Box
            key={storeBrand}
            sx={{marginTop: '3rem'}}
          >
            <h3>{storeBrand}</h3>

            <ScrapeStorebrandSummary
              storebrandInfo={stores}
              currentDateNZT={currentDateNZT}
            />
          
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'none', sm: 'flex' },
                flexWrap: 'wrap',
                marginTop: '1rem',
                marginRight: '-20px', // to negate the default padding and use the full width
              }}
            >                

              {stores.map((store, index) => (
                <ScrapeStoreCard
                  storeInfo={store}
                  currentDateNZT={currentDateNZT}
                  key={index}
                />
              ))}
            </Box>
          </Box>
        ))}
        </>
      )}
    </Paper>
  );
}
