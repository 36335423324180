import { useState } from "react";
import Box from "@mui/material/Box";

interface ScrapeStorebrandSummaryProps {
  storebrandInfo: StoreInfo[];
  currentDateNZT: string;
}

interface ScrapeRun {
  scrapeStartDateNZT: string;
  scrapeStartAt: number;
  scrapeEndAt: number;
  numSkus: number;
}

interface LatestScrapeRun {
  ageHours: number;
  dateNZT: string;
  skuCoveragePercent: number;
  numSkus: number;
}

interface StoreInfo {
  storeBrand: string;
  storeName: string;
  storeNameDebranded: string;
  scrapeRuns: ScrapeRun[];
  latestScrapeRun: LatestScrapeRun;
  recentMaxNumSkus: number;
  latestScrapeRunStatus?: string;
}

interface StatusCategory {
  status: string;
  name: string;
  count: number;
  bg: string;
}

function ScrapeStorebrandSummary(props: ScrapeStorebrandSummaryProps) {

  // storebrandInfo example:
  // [
  //     {
  //       "storeBrand": "paknsave",
  //       "storeName": "PAK'nSAVE Rotorua",
  //       "storeNameDebranded": "Rotorua",
  //       "scrapeRuns": [
  //         { "scrapeStartDateNZT": "2020-01-01", "scrapeStartAt": 1678901000, "scrapeEndAt": 1678901000, "numSkus": 10200 },
  //         { "scrapeStartDateNZT": "2020-01-02", "scrapeStartAt": 1678901360, "scrapeEndAt": 1678901480, "numSkus": 10400 }
  //       ],
  //       "latestScrapeRun": {
  //         "ageHours": 6.973960000000001,
  //         "dateNZT": "2020-01-02",
  //         "skuCoveragePercent": 99.86882716049382,
  //         "numSkus": 10400
  //       },
  //       "recentMaxNumSkus": 10400
  //     },
  //     {
  //       "storeBrand": "paknsave",
  //       "storeName": "PAK'nSAVE Queenstown",
  //       "storeNameDebranded": "Queenstown",
  //       "scrapeRuns": [
  //         { "scrapeStartDateNZT": "2020-01-02", "scrapeStartAt": 1678901535, "scrapeEndAt": 1678901688, "numSkus": 12800 }
  //       ],
  //       "latestScrapeRun": {
  //         "ageHours": 7.1334019444444445,
  //         "dateNZT": "2020-01-02",
  //         "skuCoveragePercent": 100,
  //         "numSkus": 12800
  //       },
  //       "recentMaxNumSkus": 12800
  //     }
  //   ]
  

  const storebrandInfo: StoreInfo[] = props?.storebrandInfo;
  const currentDateNZT: string = props?.currentDateNZT;

  // for each store object, add a categorization of the last scrape status
  const storebrandInfoWithScrapeStatus: StoreInfo[] = storebrandInfo.map((storeInfo: StoreInfo) => {
    const coveragePercent = storeInfo.latestScrapeRun.skuCoveragePercent || 0;
    const ageHours = storeInfo.latestScrapeRun.ageHours || 99;
    const dateNZT = storeInfo.latestScrapeRun.dateNZT || '2000-01-01';

    if (dateNZT === currentDateNZT) {
      // if the latest scrape run is from today, check the coverage
      if (coveragePercent > 98) {
        storeInfo.latestScrapeRunStatus = "todayAbove98Coverage"
      } else if (coveragePercent > 95) {
        storeInfo.latestScrapeRunStatus = "todayAbove95Coverage"
      } else if (coveragePercent > 90) {
        storeInfo.latestScrapeRunStatus = "todayAbove90Coverage"
      } else {
        storeInfo.latestScrapeRunStatus = "todayBelow90Coverage"
      }
    } else if (ageHours < 24) {
      storeInfo.latestScrapeRunStatus = "staleLessThan24Hours"
    } else if (ageHours < 48) {
      storeInfo.latestScrapeRunStatus = "staleLessThan48Hours"
    } else {
      storeInfo.latestScrapeRunStatus = "staleMoreThan48Hours"
    }

    return storeInfo
  })


  const statusCategories: StatusCategory[] = [
    {
      status: "todayAbove98Coverage",
      name: "Today, >98% coverage",
      count: storebrandInfoWithScrapeStatus.filter(store => store.latestScrapeRunStatus === "todayAbove98Coverage").length,
      bg: '#009432'
    },
    {
      status: "todayAbove95Coverage",
      name: "Today, >95% coverage",
      count: storebrandInfoWithScrapeStatus.filter(store => store.latestScrapeRunStatus === "todayAbove95Coverage").length,
      bg: 'repeating-linear-gradient(-45deg, #FFC312, #FFC312 10px, #009432 10px, #009432 20px)'
    },
    {
      status: "todayAbove90Coverage",
      name: "Today, >90% coverage",
      count: storebrandInfoWithScrapeStatus.filter(store => store.latestScrapeRunStatus === "todayAbove90Coverage").length,
      bg: 'repeating-linear-gradient(-45deg, #EE5A24, #EE5A24 10px, #009432 10px, #009432 20px)'
    },
    {
      status: "todayBelow90Coverage",
      name: "Today, <90% coverage",
      count: storebrandInfoWithScrapeStatus.filter(store => store.latestScrapeRunStatus === "todayBelow90Coverage").length,
      bg: 'repeating-linear-gradient(-45deg, #CC0000, #CC0000 10px, #009432 10px, #009432 20px)'
    },
    {
      status: "staleLessThan24Hours",
      name: "Stale, <24 hours",
      count: storebrandInfoWithScrapeStatus.filter(store => store.latestScrapeRunStatus === "staleLessThan24Hours").length,
      bg: '#FFC312'
    },
    {
      status: "staleLessThan48Hours",
      name: "Stale, <48 hours",
      count: storebrandInfoWithScrapeStatus.filter(store => store.latestScrapeRunStatus === "staleLessThan48Hours").length,
      bg: '#EE5A24'
    },
    {
      status: "staleMoreThan48Hours",
      name: "Stale, >48 hours",
      count: storebrandInfoWithScrapeStatus.filter(store => store.latestScrapeRunStatus === "staleMoreThan48Hours").length,
      bg: '#CC0000'
    }
  ];

  const [selectedCategories, setSelectedCategories] = useState([
    // "todayAbove98Coverage",
    // "todayAbove95Coverage",
    "todayAbove90Coverage",
    "todayBelow90Coverage",
    "staleLessThan24Hours",
    "staleLessThan48Hours",
    "staleMoreThan48Hours"
  ]);

  const toggleCategory = (category: string): void => {
    setSelectedCategories((prevState: string[]) => {
        if (prevState.includes(category)) {
            return prevState.filter(item => item !== category);
        } else {
            return [...prevState, category];
        }
    });
  }

  const [displayStoreNamesInSelectedCategories, setDisplayStoreNamesInSelectedCategories] = useState(false);

  const storeNamesInSelectedCategories = storebrandInfoWithScrapeStatus
    .filter(store => selectedCategories.includes(store.latestScrapeRunStatus || ''))
    .map(store => store.storeName)
    .map(storeName => storeName.replace(/'/g, ''))

  const handleMouseOver = (e: React.MouseEvent<HTMLElement>) => {
    e.currentTarget.style.cursor = 'pointer';
  };


  return (
    <Box>
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        {statusCategories.map((statusCategory) => (
          <Box
            key={statusCategory.status}
            onMouseOver={handleMouseOver}
            onClick={() => toggleCategory(statusCategory.status)}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '45px',
              height: '45px',
              padding: '5px',
              margin: '5px',
              borderRadius: '5px',
              background: statusCategory.bg,
              border: selectedCategories.includes(statusCategory.status) ? '4px solid #007BFF' : '4px solid #777777',
            }}
          >
            <h1>{statusCategory.count}</h1>
          </Box>
        ))}

        <Box
          onMouseOver={handleMouseOver}
          onClick={() => setDisplayStoreNamesInSelectedCategories(!displayStoreNamesInSelectedCategories)}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '120px',
            height: '45px',
            padding: '5px',
            margin: '5px',
            borderRadius: '5px',
            background: '#007BFF',
          }}
        >
          {displayStoreNamesInSelectedCategories ? `Hide` : `Show`} stores
        </Box>
      </Box>

      {displayStoreNamesInSelectedCategories && storeNamesInSelectedCategories.length > 0 &&
        <Box
          sx={{
            padding: '5px',
            margin: '5px',
            marginRight: '20px',
            borderRadius: '5px',
            background: '#666666',
            display: { xs: 'none', sm: 'flex' },
          }}
        >
          <p>'{JSON.stringify(storeNamesInSelectedCategories)}'</p>
        </Box>
      }

    </Box>
      
  );
}

export default ScrapeStorebrandSummary;
