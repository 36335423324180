import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useAuthContext } from '../contexts/AuthProvider';
import { Box, Typography, CircularProgress } from '@mui/material';

const Product = () => {
  const { id } = useParams();
  const [productDetail, setProduct] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { accessToken } = useAuthContext();

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await fetch(
            `/api/product/get/${id}`,
            {headers: {'Authorization': `Bearer ${accessToken}`}}
        );
        if (!response.ok) {
          throw new Error('Product not found');
        }
        const data = await response.json();
        setProduct(data);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to fetch product');
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [accessToken, id]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error" variant="h6" align="center">
        {error}
      </Typography>
    );
  }

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Product id: {id}
      </Typography>
      <Typography variant="body1">
        <pre>{JSON.stringify(productDetail, null, 2)}</pre>
      </Typography>
    </Box>
  );
}

export default Product;