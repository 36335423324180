import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import Box from "@mui/material/Box";

interface ScrapeRun {
  scrapeStartDateNZT: string;
  scrapeStartAt: number;
  scrapeEndAt: number;
  numSkus: number;
}

interface StoreInfo {
  storeBrand: string;
  storeName: string;
  storeNameDebranded: string;
  scrapeRuns: ScrapeRun[];
  latestScrapeRun: {
    ageHours: number;
    dateNZT: string;
    skuCoveragePercent: number;
    numSkus: number;
  };
  recentMaxNumSkus: number;
}

interface ScrapeStoreCardProps {
  storeInfo: StoreInfo;
  currentDateNZT: string;
}

function ScrapeStoreCard(props: ScrapeStoreCardProps) {
  const navigate = useNavigate();
  const [isHovering, setIsHovering] = useState(false);

  const storeInfo = props?.storeInfo;
  const currentDateNZT = props?.currentDateNZT;
  const coveragePercent = storeInfo.latestScrapeRun.skuCoveragePercent || 0;
  const ageHours = storeInfo.latestScrapeRun.ageHours || 99;

  let bg = 'none';
  if (storeInfo.latestScrapeRun.dateNZT === currentDateNZT) {
    if (coveragePercent > 98) {
      bg = '#009432';
    } else if (coveragePercent > 95) {
      bg = 'repeating-linear-gradient(-45deg, #FFC312, #FFC312 10px, #009432 10px, #009432 20px)';
    } else if (coveragePercent > 90) {
      bg = 'repeating-linear-gradient(-45deg, #EE5A24, #EE5A24 10px, #009432 10px, #009432 20px)';
    } else {
      bg = 'repeating-linear-gradient(-45deg, #CC0000, #CC0000 10px, #009432 10px, #009432 20px)';
    }
  } else if (ageHours < 24) {
    bg = '#FFC312';
  } else if (ageHours < 48) {
    bg = '#EE5A24';
  } else {
    bg = '#CC0000';
  }

  const storeNameFormatted = storeInfo.storeNameDebranded.split(' ').map(word => {
    if (word.length > 7) {
      return word.match(/.{1,6}/g)?.join('-');
    } else {
      return word;
    }
  }).join(' ');

  const handleMouseOver = (e: React.MouseEvent<HTMLElement>) => {
    setIsHovering(true);
    e.currentTarget.style.cursor = 'pointer';
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  return (
    <>
      <Box
        component="div"
        sx={{
          width: '3.6em',
          height: '3.6em',
          background: bg,
          fontFamily: 'monospace',
        }}
        margin={0.15}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onClick={() => navigate(`/store/${storeInfo.storeName}`)}
      >
        <Box
          component="div"
          sx={{
            height: '100%',
            pointerEvents: 'none',
          }}
        >
          {!isHovering && (
            <p
              style={{
                fontSize: '0.8em',
                lineHeight: 0.9,
                margin: 0,
                marginLeft: 3,
              }}
            >
              {storeNameFormatted}
            </p>
          )}

          {isHovering && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <p style={{ margin: 0 }}>{ageHours?.toFixed()}h</p>
              <p style={{ margin: 0 }}>{coveragePercent?.toFixed()}%</p>
            </div>
          )}
        </Box>
      </Box>
    </>
  );
}

export default ScrapeStoreCard;
